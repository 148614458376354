var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getTimezoneDone)?_c('div',[_c('div',{staticClass:"analytic-container"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"analytic-heading"},[_vm._v(" Estimated profit ")])])],1),_c('v-row',[(_vm.showRegenerate)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary"},on:{"click":_vm.doCalculate}},[_vm._v(" Recalculate ")])],1):_vm._e()],1),(_vm.showExport)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"loading":_vm.isLoadingReport,"outlined":"","large":"","color":"primary"},on:{"click":_vm.exportCsv}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-excel")]),_vm._v(" Export ")],1)],1)],1):_vm._e(),(_vm.showDatePicker)?_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.dates ? _vm.dateRangeText : 'Choose time range'))])]}}],null,false,2811839601),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_first,"items":_vm.items0,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.revenue",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.revenue))))]}},{key:"item.basecost",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.basecost))))]}},{key:"item.handling",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.handling))))]}},{key:"item.dispute",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.dispute))))]}},{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.profit))))]}},{key:"item.cr",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(((item.cr) + "%")))]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items1,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.revenue",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.revenue))))]}},{key:"item.basecost",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.basecost))))]}},{key:"item.handling",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.handling))))]}},{key:"item.dispute",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.dispute))))]}},{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.profit))))]}},{key:"item.cr",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(((item.cr) + "%")))]}},{key:"item.productsWithoutBasecost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPWB(item.missingBaseCostProducts))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isLatteAccount && !item.isFirstRow)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical")])]}}],null,true)},[_c('v-list',{staticClass:"list-action",attrs:{"nav":"","dense":""}},[_c('v-list-item',{attrs:{"disabled":item.isApproved},on:{"click":function($event){return _vm.doCalculate(item._id.x)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-reload")]),_c('span',[_vm._v("Recalculate")])],1)],1),_c('v-list-item',{attrs:{"disabled":item.isApproved},on:{"click":function($event){return _vm.openPWB(item)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(" mdi-playlist-edit")]),_c('span',[_vm._v("List product without basecost")])],1)],1),_c('v-list-item',{attrs:{"disabled":item.isApproved},on:{"click":function($event){return _vm.approveItem(item)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(" mdi-check")]),_c('span',[_vm._v("Approve")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.openDetailDialog(item)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(" mdi-clipboard-list-outline")]),_c('span',[_vm._v("Detail")])],1)],1)],1)],1):_vm._e(),(!_vm.isLatteAccount && !item.isFirstRow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.openDetailDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-clipboard-list-outline ")])]}}],null,true)},[_c('span',[_vm._v("Detail")])]):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('ListProductWithoutBasecost',{ref:"listProductWithoutBasecost"}),_c('DetailDialog',{ref:"detailDialog"})],1):_c('div',{staticClass:"analytic-loading"},[_c('Loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }