<template>
  <v-dialog persistent v-model="dialog" width="720">
    <v-card relative>
      <v-card-title class="">
        Detail
      </v-card-title>

      <v-divider class=""></v-divider>

      <v-data-table :headers="headers" :items="items" hide-default-footer>
        <template v-slot:[`item.orderName`]="{ item }">{{ item.orderOrigin.orderName || '-' }}</template>
        <template v-slot:[`item.revenue`]="{ item }">{{ `$${item.orderOrigin.grandTotal || 0}` }}</template>
        <template v-slot:[`item.basecost`]="{ item }">{{ `$${item.basecost || 0}` }}</template>
        <template v-slot:[`item.handling`]="{ item }">{{ `$${item.handling || 0}` }}</template>
        <template v-slot:[`item.dispute`]="{ item }">{{ `$${item.dispute || 0}` }}</template>
        <template v-slot:[`item.profit`]="{ item }">{{ `$${item.profit || 0}` }}</template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="params.page" :length="Math.ceil(total / 10)" />
      </div>

      <v-divider class=""></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close"> Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import analyticRequest from '@/apis/request/analyticRequest';

export default {
  name: 'DetailDialog',
  data() {
    return {
      dialog: false,
      item: {},
      headers: [
        {
          text: 'Name',
          value: 'orderName',
        },
        {
          text: 'Revenue',
          value: 'revenue',
        },
        {
          text: 'Basecost',
          value: 'basecost',
        },
        {
          text: 'Handling',
          value: 'handling',
        },
        {
          text: 'Dispute',
          value: 'dispute',
        },
        {
          text: 'Profit',
          value: 'profit',
        },
      ],
      items: [],
      params: {
        page: 1,
        limit: 10,
      },
      total: 0,
    };
  },
  methods: {
    open(item) {
      this.dialog = true;
      this.item = item;
      this.getData();
    },
    close() {
      this.items = [];
      this.dialog = false;
    },
    async getData() {
      try {
        let data = await analyticRequest.get(`/actions/estimated_profit/detail?x=${this.item._id.x}`, {
          params: this.params,
        });
        this.items = data.data.data;
        this.total = data.data.pagination.total;
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    'params.page': function() {
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.v-data-table {
  margin-left: 20px;
  margin-right: 20px;
}
</style>
