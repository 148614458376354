var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"720"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"relative":""}},[_c('v-card-title',{},[_vm._v(" Detail ")]),_c('v-divider',{}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.orderName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.orderOrigin.orderName || '-'))]}},{key:"item.revenue",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.orderOrigin.grandTotal || 0))))]}},{key:"item.basecost",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.basecost || 0))))]}},{key:"item.handling",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.handling || 0))))]}},{key:"item.dispute",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.dispute || 0))))]}},{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("$" + (item.profit || 0))))]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.total / 10)},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1),_c('v-divider',{}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v(" Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }