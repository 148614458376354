<template>
  <v-dialog persistent v-model="dialog" width="720">
    <v-card relative>
      <v-card-title class="">
        List product without basecost
      </v-card-title>

      <v-divider class=""></v-divider>
      <v-card-text class="mt-6">
        <p v-for="(item, index) in listProduct" :key="index" class="product-name">
          {{ index + 1 }}.
          <a :href="item.link" target="_blank">
            {{ item.name }}
          </a>
        </p>
      </v-card-text>
      <v-divider class=""></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close"> Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ListProductWithoutBasecost',
  data() {
    return {
      dialog: false,
      listProduct: [],
    };
  },
  methods: {
    open(item) {
      this.dialog = true;
      this.listProduct = item;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped lang="scss">
.product-name {
  font-size: 16px;
  color: black;

  a {
    color: #000;
  }
}
</style>
